/* rails-erb-loader-dependencies helpers/application_helper */
import Vue from 'vue'


          import { ButtonPlugin } from 'bootstrap-vue'
          Vue.use(ButtonPlugin)
        
          import { CardPlugin } from 'bootstrap-vue'
          Vue.use(CardPlugin)
        
          import { FormPlugin } from 'bootstrap-vue'
          Vue.use(FormPlugin)
        
          import { FormCheckboxPlugin } from 'bootstrap-vue'
          Vue.use(FormCheckboxPlugin)
        
          import { FormGroupPlugin } from 'bootstrap-vue'
          Vue.use(FormGroupPlugin)
        
          import { FormInputPlugin } from 'bootstrap-vue'
          Vue.use(FormInputPlugin)
        
          import { ImagePlugin } from 'bootstrap-vue'
          Vue.use(ImagePlugin)
        
          import { LayoutPlugin } from 'bootstrap-vue'
          Vue.use(LayoutPlugin)
        
          import { LinkPlugin } from 'bootstrap-vue'
          Vue.use(LinkPlugin)
        
          import { NavbarPlugin } from 'bootstrap-vue'
          Vue.use(NavbarPlugin)
        


          import LogoRevolugo from '../../components/shared/LogoRevolugo/LogoRevolugo.vue'
          Vue.component('LogoRevolugo', LogoRevolugo)
        


      import {
        library
      } from '@fortawesome/fontawesome-svg-core'
      import {
        FontAwesomeIcon
      } from '@fortawesome/vue-fontawesome'
      import { faGlobe } from '@fortawesome/free-solid-svg-icons'
      library.add(faGlobe)

      Vue.mixin({
        components: {
          'fa-icon': FontAwesomeIcon
        }
      })
    

import importPlugins from '../../plugins/index.js'

importPlugins([
  'get-color'
])(Vue)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app-footer',
  })
})
